import gsap from 'gsap'
import { useIntersectionObserver } from '../helpers'

class LogoHero {
    constructor(element) {
        this.element = element

        this.logos = document.querySelectorAll('.main-logo')

        this.element.resetOnExit = true

        this.hasShown = false

        if (self.Decimal.isMobile) {
            gsap.set(this.logos, { autoAlpha: 0 })
        }
        useIntersectionObserver(this, null, {
            threshold: [0, 0]
        })
    }

    onViewportEnter() {
        if (!this.hasShown) {
            this.hasShown = true
            return
        }
        gsap.fromTo(this.logos, {
            autoAlpha: 1
        }, {
            autoAlpha: 0,
            duration: 0.4,
            ease: 'linear'
        })

        gsap.fromTo(this.logos, {
            y: 0
        }, {
            duration: 0.35,
            y: 30,
            ease: 'expo.out'
        })
    }

    /* Intersection Observer callback for exit
                                          * can be used to reset animation (timeline?)
                                          */
    onViewportExit() {
        gsap.fromTo(this.logos, {
            y: 30
        }, {
            duration: 0.35,
            y: 0,
            ease: 'Power4.inOut'
        })

        gsap.fromTo(this.logos, {
            autoAlpha: 0
        }, {
            duration: 0.5,
            autoAlpha: 1,
            ease: 'linear'
        })
    }
}

// IIFE to invoke component on mount
(function init() {
    let logoHero
    const TestBlockElements = Array.from(
        document.querySelectorAll('[data-logo-hero]')
    )

    if (TestBlockElements.length > 0) {
        TestBlockElements.forEach((el) => {
            logoHero = new LogoHero(el)
        })
    }

    self.Decimal.components.LogoHero = { ...logoHero, async: true }
})()
